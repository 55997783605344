import React from "react"
import "./styles.css"
import { graphql, Link, useStaticQuery } from "gatsby"
import Arrow from "../../images/arrow2.svg"
import Waf from "../../images/waf.svg"
import Av from "../../images/av.svg"
import Healthboard from "../../images/healthboard.svg"
import Img from "gatsby-image"
import { useTranslation } from "../../utils"

const WpFeaturesSection = () => {
  const { t } = useTranslation()
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "wordpress_features.png" }) {
        childImageSharp {
          fluid(maxWidth: 555) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <section className="wp-features-section">
      <div className="container">
        <div className="row">
          <div className="col-4 col-md-6 col-sm-12 wt-margin-bottom-20">
            <div className="wp-features-section__content">
              <div className="wp-features-section__title">
                {t("Our features")}
              </div>
              <div className="wp-features-section__text">
                {t(
                  "We cover all stages of cyber attacks with the help of our rich modular functionality"
                )}
              </div>
              <Link className="wp-features-section__link" to="/">
                <span className="wt-margin-right-10">
                  {t("View all features of WebTotem")}
                </span>
                <img src={Arrow} alt="arrow" />
              </Link>
            </div>
          </div>

          <div className="col-8 col-md-6 col-sm-12 display-sm-none display-xs-none">
            <div className="wp-features-section__img">
              <Img fluid={data.placeholderImage.childImageSharp.fluid} />
            </div>
          </div>

          <div className="col-4 col-sm-12 wt-margin-bottom-20">
            <div className="wp-features-section__block">
              <div className="wp-features-section__icon wt-margin-bottom-15">
                <img src={Waf} width={27} height={27} alt="Waf" />
              </div>
              <div className="wp-features-section__feature">
                {t("Track website activity")}
              </div>
              <div className="wp-features-section__text">
                {t(
                  "Track the activity, performance and blocked attacks on your website in real-time"
                )}
              </div>
            </div>
          </div>

          <div className="col-4 col-sm-12 wt-margin-bottom-20">
            <div className="wp-features-section__block">
              <div className="wp-features-section__icon wt-margin-bottom-15">
                <img src={Av} width={27} height={27} alt="Waf" />
              </div>
              <div className="wp-features-section__feature">
                {t("Scan for malware & hidden threats")}
              </div>
              <div className="wp-features-section__text">
                {t(
                  "Continuously scan your website files for any potentially harmful injections, eg.: shells, backdoors, malware"
                )}
              </div>
            </div>
          </div>

          <div className="col-4 col-sm-12 wt-margin-bottom-20">
            <div className="wp-features-section__block">
              <div className="wp-features-section__icon wt-margin-bottom-15">
                <img src={Healthboard} width={27} height={27} alt="Waf" />
              </div>
              <div className="wp-features-section__feature">
                {t("All in one dashboard")}
              </div>
              <div className="wp-features-section__text">
                {t(
                  "Get all information about the current state of your website in one comprehensive dashboard without leaving your WordPress administration panel"
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WpFeaturesSection
