import React from "react"
import CloudDownloadIcon from "../../images/cloud-download.svg"
import KeyIcon from "../../images/key.svg"
import SwitchIcon from "../../images/switch.svg"
import "./styles.css"
import { useTranslation } from "../../utils"

const WpQualitySection = () => {
  const { t } = useTranslation()
  return (
    <section className="wp-quality-section">
      <div className="container">
        <div className="wp-quality-section__header">
          <div className="wp-quality-section__title">{t("Quick&Easy")}</div>
        </div>
        <div className="row">
          <div className="col-4 col-sm-12">
            <div className="wp-quality-section__block">
              <div className="wp-quality-section__icon">
                <img src={CloudDownloadIcon} alt="cloud" />
              </div>
              <div className="wp-quality-section__number">1</div>
              <div className="wp-quality-section__step">
                {t("Download the plugin")}
              </div>
              <div className="wp-quality-section__text">
                {t(
                  "Install on your WordPress web-site manually or via WordPress marketplace"
                )}
              </div>
            </div>
          </div>
          <div className="col-4 col-sm-12">
            <div className="wp-quality-section__block">
              <div className="wp-quality-section__icon">
                <img src={KeyIcon} alt="key" />
              </div>
              <div className="wp-quality-section__number">2</div>
              <div className="wp-quality-section__step">{t("Get API-Key")}</div>
              <div className="wp-quality-section__text">
                {t("Get API-Key and activate the WordPress plugin")}
              </div>
            </div>
          </div>
          <div className="col-4 col-sm-12">
            <div className="wp-quality-section__block">
              <div className="wp-quality-section__icon">
                <img src={SwitchIcon} alt="key" />
              </div>
              <div className="wp-quality-section__number">3</div>
              <div className="wp-quality-section__step">
                {t("Antivirus & WAF")}
              </div>
              <div className="wp-quality-section__text">
                {t(
                  "After installation you can install automaticlly the WAF and Antivirus"
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WpQualitySection
