import React from "react"
import WordpressLogo from "../../images/wordpress.png"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import "./styles.css"
import { useTranslation } from "../../utils"
import { useAnalytics } from "../../hooks/useTracking"

const WpPluginSection = () => {
  const { t } = useTranslation()
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "wordpress_dashboard.png" }) {
        childImageSharp {
          fluid(maxWidth: 555) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const analytics = useAnalytics()

  return (
    <section className="wp-main-section">
      <div className="container">
        <div className="wt-flex wt-flex-middle">
          <div className="wp-main-section__content">
            <div className="wp-main-section__title">
              <img
                src={WordpressLogo}
                alt="Wordpress logo"
                width={60}
                height={60}
              />
              <span className="wt-margin-left-10">{t("WordPress")}</span>
            </div>
            <div className="wp-main-section__text">
              {t("Introducing WebTotem to WordPress community")}
            </div>
            <div className="wp-main-section__hint">
              {t("Website security and monitoring Plugin for WordPress")}
            </div>
            <a
              onClick={analytics.downloadPlugin}
              className="wt-button"
              href="https://wordpress.org/plugins/wt-security/"
              target="_blank"
            >
              {t("Download plugin")}
            </a>
          </div>

          <div className="wp-main-section__image display-sm-none display-xs-none">
            <Img fluid={data.placeholderImage.childImageSharp.fluid} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default WpPluginSection
