import React from "react"
import Layout from "../../components/layout"
import Header from "../../sections/header"
import SEO from "../../components/seo"
import WpPluginSection from "../../sections/wp-plugin"
import WpFeaturesSection from "../../sections/wp-features"
import WpQualitySection from "../../sections/wp-quality"
import Footer from "../../sections/footer"

const WordpressPage = () => {
  return (
    <Layout>
      <SEO title="Wordpress plugin" />
      <Header />

      <WpPluginSection />
      <WpFeaturesSection />

      <div className="wt-divider wt-margin-top-40 wt-margin-bottom-25" />

      <WpQualitySection />

      <Footer />
    </Layout>
  )
}

export default WordpressPage
